import DetectingBotsWithJavascript from './component/blog/DetectingBotsWithJavascript';

import SearchEmail from './component/SearchEmail';

const { Typography, Link } = require("@mui/material")
const { default: EmailDetectiveLogo } = require("./component/EmailDetectiveLogo")
const { default: Logo } = require("./component/Logo")



const API_URL = "https://api.emaildetective.io"
// const API_URL = "http://localhost:2022"


const PRIMARY_COLOR = "#3fa34d"
// const PRIMARY_COLOR = "#1976d2"
// exports.PRIMARY_COLOR = PRIMARY_COLOR

// const BACKGROUND_COLOR = '#fbfbfb'
// exports.BACKGROUND_COLOR = BACKGROUND_COLOR

const DEFAULT_LOGO = EmailDetectiveLogo
// const DEFAULT_LOGO = Logo
// exports.DEFAULT_LOGO = DEFAULT_LOGO
 
const emailVerificationResult = `$ curl -H 'x-api-key: api-key-here' https://api.emaildetective.io/emails/example123@gmail.com
{
  "email": "example123@gmail.com",
  "username": "example123",
  "domain": "gmail.com",
  "valid_email": true,
  "valid_spf": true,
  "valid_dmarc": true,
  "valid_mx": true,
  "valid_tld": true,
  "nonsense": false,
  "role": false,
  "free": true,
  "disposable": false,
  "score": 95,
  "suspicion_rating": "LOW"
}`
const bulkEmailVerificationResult = `$ curl -H 'x-api-key: api-key-here' "https://api.emaildetective.io/emails" --data '{"data": ["example123@gmail.com", "example123@example.com"]}'
{
  "data": [
    {
      "email": "example123@gmail.com",
      "username": "example123",
      "domain": "gmail.com",
      "valid_email": true,
      "valid_spf": true,
      "valid_dmarc": true,
      "valid_mx": true,
      "valid_tld": true,
      "nonsense": false,
      "role": false,
      "free": true,
      "disposable": false,
      "score": 95,
      "suspicion_rating": "LOW"
    },
    {
      "email": "example123@email.com",
      "username": "example123",
      "domain": "email.com",
      "valid_email": true,
      "valid_spf": true,
      "valid_dmarc": true,
      "valid_mx": true,
      "valid_tld": true,
      "nonsense": false,
      "role": false,
      "free": true,
      "disposable": false,
      "score": 95,
      "suspicion_rating": "LOW"
    }
  ]
}`

const codeDocsConfig = [
  {
    id: "email_verification",
    title: "Email verification",
    subTitle: "Perform email address verification and get back information related to email address.",
    swaggerDocs: "https://api.emaildetective.io/docs/#/email%20info/getEmailAddressInfo",
    code: emailVerificationResult,
  },
  {
    id: "bulk_email_verification",
    title: "Bulk email verification",
    subTitle: "Verify multiple email addresses in one request. Maximum amount of 100 emails per request.",
    swaggerDocs: "https://api.emaildetective.io/docs/#/email%20info/getBulkEmailAddressInfo",
    code: bulkEmailVerificationResult
  }
]

// pricing information
const pasAsYouGoTier = [
    {
        title: 'Basic',
        price: '24',
        occurence: null,
        description: [
          '25,000 credits',
          'Email support'
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 793977,
        message: "Upgrade to basic plan"
    },
    {
      title: 'Premium',
        subheader: 'Most popular',
        price: '49',
        occurence: null,
        description: [
          '55,000 credits',
          'Email support'
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        productId: 799114,
        message: "Upgrade to premium plan"
    },
    {
        title: 'Enterprise',
        price: '99',
        occurence: null,
        description: [
          '120,000 credits',
          'Email support'
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 799115,
        message: "Upgrade to enterprise plan"
    },
];

const monthlyTiers = [
    {
        title: 'Basic',
        price: '19',
        occurence: 'mo',
        description: [
          '25,000 credits',
          'Email support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 810281,
        message: "Upgrade to basic plan"
    },
    {
        title: 'Premium',
        subheader: 'Most popular',
        price: '39',
        occurence: 'mo',
        description: [
          '55,000 credits',
          'Email support'
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        productId: 810282,
        message: "Upgrade to premium plan"
    },
    {
        title: 'Enterprise',
        price: '79',
        occurence: 'mo',
        description: [
          '120,000 credits',
          'Email & Phone support'
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 810283,
        message: "Upgrade to enterprise plan"
    },
];

// blog config
const blogPostMapping = {
    "detecting-bots-with-javascript": {
        id: "detecting-bots-with-javascript",
        image: "/node-js-logo.png",
        date: "11/03/2022",
        width: 70,
        height: 70,
        title: "Javascript - Detecting bots and non-human users",
        subTitle: "Get client IP address and detect non-human users with javascript and express",
        body: <DetectingBotsWithJavascript/>,
    },
}

// changelog information
const changeLogs = [
    {
      "title": "Added Google Authentication",
      "date": "02/02/2023",
      "tag": "new",
      "paragraphs": [
        "Google login has been added and can be accessed by simply going to the login page. All previous gmail accounts can now login either via email login or by google sign in.",
      ]
    },
]


const PAGE_CONFIG = {
    home: {
        summary: () => (
            <Typography>
              EmailDetective is an API that focuses on email validation and legitimacy scoring, helping to identify whether an email address is valid, gibberish, deliverable or disposable.
            </Typography>
        ),
        intro_video: "Nx8yPGO9m1M",
        free_summary: "EmailDetective is free to use up to 100 requests per month. Commercial plans are available for those with greater needs.",
        fast_summary: "EmailDetective focuses on speed. Email address verification should be fullfilled as quickly as possible.",
        simple_summary: "APIs are designed to be simple, enabling email verification from any application or programming language.",
        about_us_summary: "EmailDetective was originally developed as an internal solution for our projects and has now been made available to the public. If you encounter suspicious or disposable email addresses, feel free to reach out to us. We're dedicated to maintaining the most up-to-date and reliable email detection tool on the market.",
        customers: [
          {
            image_path: "/the-shoppad-logo.svg",
            width: "300px",
            height: "200px",
            background: "white",
            title: "The Shoppad | Helping Ecommerce Business Grow"
          },
          {
            image_path: "/social-blade-logo.webp",
            width: "300px",
            height: "200px",
            background: "black",
            title: "Social Blade"
          },
          {
            image_path: "/mesa-logo.svg",
            width: "300px",
            height: "200px",
            background: "white",
            title: "MESA | A shopify e-commererce automation platform"
          }
        ]
      },
    api: {
        code_docs: codeDocsConfig,
        subtitle: "",
        additional_information: `
          EmailDetective is committed to delivering the most up-to-date insights on email addresses. 
          Our goal is to help reduce the presence of fake and disposable email addresses, as well as to streamline email list management. 
          While we have no issue with disposable email services, it's important to note that fake or temporary addresses can significantly impact user engagement. 
          To maintain the integrity of consumer-facing applications and email lists, effective mitigation strategies are essential.`
    },
    pricing: {
        pay_as_you_go_tiers: pasAsYouGoTier,
        monthly_tiers: monthlyTiers,
        sign_in_for_free: "Sign in to try EmailDetective for FREE"
    },
    faq: [
        {
          title: "What is a credit?",
          info: function() {
            return (
            <Typography>
              A credit allows you to validate an email. For example 100 free credits are rewarded every month. This means that you can verify and get information about 100 email addresses per month.
            </Typography>)
          },
        },
        {
          title: "How many credits do I get per month?",
          info: function() {
            return (
            <Typography>
              The free trial provides 100 credits per month. 
              If you need to increase this limit please see <Link href="/pricing">pricing</Link>.
            </Typography>)
          },
        },
        {
          title: "Can I validate multiple email addresses in one request?",
          info: function() {
            return (
            <Typography>
              Yes, check out the <Link href="/api#bulk_email_verification">bulk email verification</Link> endpoint to check multiple email addresses.
            </Typography>)
          },
        },
        {
          title: "I have used all my credits. How do I increase my credits?",
          info: function() {
            return (
            <Typography>
              If you are interested in more credits please see the <Link href="/pricing">pricing plans</Link> and select one that meets your needs
            </Typography>)
          },
        },
        {
          title: "How is the email address validated?",
          info: function() {
            return (
            <Typography>
              Email addresses are validated at multiple levels. 
              First it must be a valid email.
              We also perform network verification using MX, SPF and DMARC protocols. 
              Emails are also determined if they are disposable or free email addresses.
            </Typography>)
          },
        }
    ],
    contact: {
        contact_subjects: [
            {
              value: "general",
              text: "General"
            },
            {
              value: "upgrade",
              text: "Upgrade Plan"
            },
            {
              value: "inaccuracies",
              text: "Invalid results or errors"
            },
            {
              value: "billing",
              text: "Billing"
            },
            {
              value: "security",
              text: "Security/Bug Report"
            },
          ]
    },
    search: {
        // the search page is rather customizable since how ips are handled is different than emails
        component: SearchEmail
    },
    blog: {
        blogs: blogPostMapping,
    },
    login: {
        subtitle: "Enter your email below to login/register to EmailDetective."
    },
    changelog: {
        change_logs: changeLogs
    }
}

const TRACKING_CODE = 'G-BNQ6QNGBFH'

export {PAGE_CONFIG, DEFAULT_LOGO, PRIMARY_COLOR, API_URL, TRACKING_CODE}